@import url('https://rsms.me/inter/inter.css');

/* Reset box-model (source: destyle.css)
   ========================================================================== */

* {
  box-sizing: border-box;
}

::before,
::after {
  box-sizing: inherit;
}

blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
}

html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif
  }
}

body {
  margin: 0;
}
